import React, { useEffect, useState } from 'react'
import CustomTabs from '../../components/common/CTabs'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import OrderList from './Order/OrderList'
import EventList from './Event/EventList'

const tabs = [
  { key: 'order', label: 'Order Report' },
  { key: 'event', label: 'Event Report' },
]

const ReportList: React.FC = () => {
  const [activeKey, setActiveKey] = useState('order')
  const { t } = useTranslation()

  const handleTabChange = (key: string) => {
    setActiveKey(key)
    localStorage.setItem('active', key)
  }

  useEffect(() => {
    const storedActiveTab = localStorage.getItem('active')
    if (storedActiveTab) {
      setActiveKey(storedActiveTab)
    }
  }, [])

  const componentMapping: { [key: string]: React.FC<{ status: string }> } = {
    order: OrderList,
    event: EventList
  }

  const Component = componentMapping[activeKey]

  return (
    <>
      <p className='page-heading'>{t('Reports')}</p>
      <CustomTabs
        tabs={tabs}
        activeKey={activeKey}
        onTabChange={handleTabChange}
      />
      {Component && <Component status={activeKey} />}
    </>
  )
}

export default ReportList
