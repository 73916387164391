import React, { useState } from 'react'
import CustomTabs from '../../components/common/CTabs'
import CInput from '../../components/common/CInput'
import { Theme } from '../../Theme'
import CTable from '../../components/common/CTable'
import { ColumnsType } from 'antd/lib/table'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomAlert from '../../components/common/CAlert'
import { getAllVendorFreelancerServices } from '../../services/freelancer'
import _ from 'lodash'
import Status from '../../components/common/Status'
import EmptyList from '../../components/common/EmptyList'
import CButton from '../../components/common/CButton'
import { vendorActiveDeactiveService } from '../../services/service'
import AlertModal from '../../components/common/AlertModal'
import { Switch } from 'antd'

const tabs = [
  { key: 'All', label: 'All Services' },
  { key: 'NewRequest', label: 'New Service Requests' },
  { key: 'Active', label: 'Active Services' },
  { key: 'InActive', label: 'Deactivate Services' }
]

const ServicesAndPackagesList: React.FC = () => {
  const [userWillActive, setUserWillActive] = useState<{
    show: boolean
    status: boolean
    id: number | null
  }>({
    show: false,
    status: false,
    id: null
  })
  const [alert, setAlert] = useState<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const activeKey = searchParams.get('Status') || 'All'
  const pageNumber = Number(searchParams.get('PageNumber')) || 1
  const pageSize = Number(searchParams.get('PageSize')) || 10
  const search = searchParams.get('Search') || ''
  const sortOrder = searchParams.get('SortOrder') || 'z'

  const handleTabChange = (key: string) => {
    setSearchParams(
      { Status: key, PageNumber: '1', PageSize: pageSize.toString() },
      { replace: true }
    )
  }

  // Pagination handling
  const handleTableChange = (page: number, pageSize: number) => {
    setSearchParams(
      {
        Status: activeKey,
        PageNumber: page.toString(),
        PageSize: pageSize.toString()
      },
      { replace: true }
    )
  }

  const handleSearchChange = _.debounce((e: any) => {
    setSearchParams(
      {
        Status: activeKey,
        PageNumber: '1',
        PageSize: pageSize.toString(),
        Search: e.target.value
      },
      { replace: true }
    )
  }, 1000)

  const handleSorting = () => {
    const newSortOrder = sortOrder === 'z' ? 'a' : 'z'
    setSearchParams(
      {
        Status: activeKey,
        PageNumber: pageNumber.toString(),
        PageSize: pageSize.toString(),
        Search: search,
        SortOrder: newSortOrder
      },
      { replace: true }
    )
  }

  const handleSwitchChange = (user: any) => {
    setUserWillActive({ show: true, status: user?.active, id: user?.id })
  }

  const columns: ColumnsType = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('Service Name'),
      dataIndex: 'serviceName',
      key: 'serviceName'
    },
    {
      title: t('Category'),
      dataIndex: 'categoryName',
      key: 'categoryName'
    },
    {
      title: t('No of Packages'),
      dataIndex: 'no_of_packages',
      key: 'no_of_packages',
      render: (text, record) => (
        <div>{record.packagesCount ? record.packagesCount : 0}</div>
      )
    },
    {
      title: t('Location'),
      dataIndex: 'serviceLocation',
      key: 'serviceLocation'
    },
    {
      title: t('Service Status'),
      key: 'status',
      render: (text, record) => (
        <div>
          <Status status={record.status} />
        </div>
      )
    },
    // {
    //     title: t("Number of Packages"),
    //     dataIndex: 'serviceCount',
    //     key: 'serviceCount',
    // },
    {
      title: t('action'),
      render: (text, record) => (
        <div className='d-flex  align-items-center'>
          <img
            src={Theme.icons.icn_view_list}
            alt='icn_view_list'
            style={{ height: '20px' }}
            className='cursor-pointer'
            onClick={() => navigate(`/jive/viewService/${record?.id}`)}
          />
         {record.status == 'Approved' && (
            <Switch
            checked={record?.active}
            onChange={() => handleSwitchChange(record)}
            className='mx-2'
          />
         )} 
        </div>
      ),
      key: 'action'
    }
  ]

  const { data, error, isLoading } = useQuery(
    [
      'VendorFreelancerServices',
      activeKey,
      pageNumber,
      pageSize,
      search,
      sortOrder
    ],
    () =>
      getAllVendorFreelancerServices({
        Status: activeKey,
        PageNumber: pageNumber,
        PageSize: pageSize,
        Search: search,
        SortColumn: 'Id',
        SortOrder: sortOrder
      }),
    {
      // keepPreviousData: true,
      onError: (err: any) => {
        setAlert({
          message: err?.message || t('An error occurred'),
          type: 'error',
          visible: true
        })
      }
    }
  )

  const { mutate, isLoading: statusLoading } = useMutation(
    vendorActiveDeactiveService,
    {
      onMutate: async (newStatus: any) => {
        await queryClient.cancelQueries(['VendorFreelancerServices'])
        const previousData = queryClient.getQueryData(['VendorFreelancerServices'])
        return { previousData }
      },
      onError: (err: any, newStatus, context) => {
        queryClient.setQueryData(['VendorFreelancerServices'], context?.previousData)
        setAlert({
          message: err?.message || t('Failed to update status'),
          type: 'error',
          visible: true
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries(['VendorFreelancerServices'])
      },
      onSuccess: () => {
        setAlert({
          message: t('Status Updated successfully!'),
          type: 'success',
          visible: true
        })
      }
    }
  )

  const handleModalOk = () => {
    if (userWillActive.id !== null) {
      const newStatus = !userWillActive.status
      console.log('max Val==+>>>', userWillActive)
      mutate({ id: userWillActive.id, Active: newStatus })
    }
    setUserWillActive({ show: false, status: false, id: null })
  }

  const handleModalCancel = () => {
    setUserWillActive({ show: false, status: false, id: null })
  }

  const isEmpty = !data?.data?.length && !data?.totalRecords
  const redirectUrl = `/jive/createService`

  return (
    <>
      <p className="page-heading">{t("Services & Packages")}</p>
      <CustomTabs
        tabs={tabs}
        activeKey={activeKey}
        onTabChange={handleTabChange}
      />
      {isEmpty && activeKey == 'All' && search == '' ? (
        <>
          <div
            style={{
              backgroundColor: Theme.colors.tabsColor,
              borderRadius: '10px'
            }}
          >
            <EmptyList
              heading={t('No Service Created yet!')}
              description={t('There are no services available.')}
              buttonLabel={t('Create New Service')}
              icon={
                <img
                  alt=''
                  src={Theme.icons.icn_service_setting}
                  style={{ height: '20px' }}
                  className='icon-white'
                />
              }
              onButtonClick={() => navigate(`${redirectUrl}`)}
            />
          </div>
        </>
      ) : (
        <>
          <div className='d-flex justify-content-between align-items-center'>
            <p className='m-0 list-heading'>{`List of ${activeKey} Services`}</p>
            <div className='d-flex justify-content-between gap-2'>
              <CInput
                prefix={
                  <img
                    alt=''
                    src={Theme.icons.icn_search}
                    style={{ height: '16px' }}
                  />
                }
                className='searchInput'
                placeholder={t('Search')}
                onChange={handleSearchChange}
              />
              <div
                className='calendar-div'
                style={{ backgroundColor: '#D0C64F' }}
                onClick={() => handleSorting()}
              >
                <img
                  src={Theme.icons.icn_sorting}
                  alt='icn_user_add'
                  style={{
                    height: '20px',
                    transform:
                      sortOrder === 'z' ? 'rotate(0deg)' : 'rotate(180deg)', // Rotates the icon by 180 degrees for ascending
                    transition: 'transform 0.3s ease-in-out' // Smooth transition for the rotation
                  }}
                />
              </div>
              <CButton
                className='addUserBtn'
                onClick={() => navigate(`${redirectUrl}`)}
              >
                <img
                  src={Theme.icons.icn_service_setting}
                  alt='icn_add_btn'
                  style={{ height: '20px' }}
                  className='cursor-pointer'
                />
                <p className='m-0'>{t('Create New Service')}</p>
              </CButton>
            </div>
          </div>

          <CTable
            footer='End of Table'
            columns={columns}
            dataSource={data?.data}
            pagination={{
              showSizeChanger: false,
              showQuickJumper: false,
              current: pageNumber,
              pageSize: pageSize,
              total: data?.totalRecords,
              onChange: handleTableChange,
              showTotal: (total: number, range: [number, number]) => (
                <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
              ),
              itemRender: (page, type, originalElement) => {
                if (type === 'prev') {
                  return <p className='detail-label'>{`< Prev`}</p>
                }
                if (type === 'next') {
                  return <p className='detail-label'>{`Next >`}</p>
                }
                return originalElement
              }
            }}
            loading={isLoading}
          />
        </>
      )}
      <AlertModal
        visible={userWillActive?.show}
        message={t(
          `Are you sure you want to ${
            userWillActive?.status ? 'deactivate' : 'activate'
          } this Service?`
        )}
        onDelete={handleModalOk}
        onCancel={handleModalCancel}
      />
      <CustomAlert
        message={alert.message}
        type={alert.type}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default ServicesAndPackagesList
