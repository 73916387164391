import instance from "./api/api_instance";

export const getVendorFreelancerOrderReports = async (credentials: object) => {
    const response = await instance.get("VendorFreelancerReports/GetVendorFreelancerOrderReports", {
      params: credentials,
    });
    return response.data;
  };

  export const getOrderReportExportToExcel = async (credentials: object) => {
    const response = await instance.get("AdminReportsController/AdminOrderReportExportToExcel", {
      params: credentials,
      responseType: 'blob',
    });
    return response.data;
  };

  export const getAllCustomerOrderReports = async (credentials: object) => {
    const response = await instance.get("AdminReportsController/GetCustomerOrderReports", {
      params: credentials,
    });
    return response.data;
  };

  export const getCustomerReportExportToExcel = async (credentials: object) => {
    const response = await instance.get("AdminReportsController/CustomerOrderReportExportToExcel", {
      params: credentials,
      responseType: 'blob',
    });
    return response.data;
  };

  export const getVendorFreelancerReports = async (url : any, credentials: Object) =>{
    const response = await instance.get(url, {
      params: credentials,
    });
    return response.data;
  }

  export const getVendorFreelancerReportExportToExcel = async (url : any, credentials: object) => {
    const response = await instance.get(url, {
      params: credentials,
      responseType: 'blob',
    });
    return response.data;
  };