import instance from "./api/api_instance";


export const login = async (credentials: object) => {
  const response = await instance.post('VendorFreelancerAuth/Login', credentials);
  return response.data;
};

export const forgot = async (credentials: object) => {
  const response = await instance.post('VendorFreelancerAuth/ForgotPassword', credentials);
  return response.data;
};

export const reset = async (credentials: object) => {
  const response = await instance.post('VendorFreelancerAuth/ResetPassword', credentials);
  return response.data;
};

export const changePassword = async (credentials: object) => {
  const response = await instance.post('VendorFreelancerAccount/ChangePassword', credentials);
  return response.data;
};

export const resendTwoFactorCode = async (credentials: object) => {
  const response = await instance.get("VendorFreelancerAuth/ResendTwoFactorCode", {
    params: credentials,
  });
  return response.data;
};

export const otpVerification = async (credentials: object) => {
  const response = await instance.post('VendorFreelancerAuth/OTPVerification', credentials);
  return response.data;
};