import React, { ReactNode, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CButton from '../../components/common/CButton'
import { Theme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import { getTheFirstTwoLetterOfString } from '../../utils/functions'
import { ReportType, reportConfig } from './ViewReportsConfig'

const PdfPreviewReport: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const contentRef = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    contentRef
  })

  const { data, endDate, startDate, type } = location.state
  const reportType: ReportType = type ? type : 'order'
  const reportFields = reportConfig[reportType]

  return (
    <>
      <div
        style={{
          backgroundColor: Theme.colors.white,
          padding: '20px',
          borderRadius: '12px'
        }}
      >
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            <img
              src={Theme.icons.icn_arrow_back}
              className='cursor-pointer'
              alt='icn_user_add'
              style={{ height: '20px' }}
              onClick={() => navigate(-1)}
            />
            <p
              className='m-0 mx-2 list-heading'
              style={{ textTransform: 'capitalize' }}
            >
              {t(`List of ${reportType} Report`)}
            </p>
          </div>

          <CButton className='addUserBtn' onClick={() => handlePrint()}>
            <img
              src={Theme.icons.icn_export}
              alt='icn_user_add'
              style={{ height: '20px' }}
            />
            <p className='m-0'>{t('Export')}</p>
          </CButton>
        </div>

        <div ref={contentRef}>
          <style
            dangerouslySetInnerHTML={{
              __html: `
                .top-logo { width: 156px; }
                .top-logo img { max-width: 100%; max-height: 100%; }
                .equal { width: 33.33%; }
                .label { color: #555; font-size: 10px; }
                .details { font-size: 12px; font-weight: 600; max-width: 100%; overflow-wrap: break-word; word-break: break-all;}
                .bottom-footer { font-size: 12px; display: flex; justify-content: space-between; }
                .page-number { font-size: 14px; font-weight: 600; }
              `
            }}
          />
          <table
            style={{
              width: '100%',
              maxWidth: 800,
              margin: '0 auto',
              backgroundColor: Theme.colors.white,
              borderSpacing: '0 10px',
              borderCollapse: 'separate',
              padding: '10px'
            }}
          >
            {/* Header */}
            <tbody>
              <tr>
                <td
                  style={{ padding: 10, textAlign: 'left' }}
                  className='top-logo full-width equal'
                >
                  <img
                    src={Theme.icons.icn_main_logo}
                    alt='Logo'
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </td>
                <td
                  style={{ textAlign: 'center', padding: '10px 20px' }}
                  className='full-width equal'
                >
                  <span className='label'>Reports Date</span> <br />
                  <span className='details'>
                    {`${startDate} - ${endDate}`}{' '}
                  </span>
                </td>
                <td
                  style={{
                    padding: 10,
                    textAlign: 'right',
                    fontSize: 28,
                    fontWeight: 600,
                    color: 'black'
                  }}
                  className='full-width equal'
                >
                  Reports
                </td>
              </tr>
              {data?.data?.map((record: any, index: number) => {
                return (
                  <tr
                    key={index}
                    className={`item ${
                      index % 3 === 2 && data.data.length > 3
                        ? 'page-break'
                        : ''
                    }`}
                  >
                    <td colSpan={3} style={{ padding: '10px 15px' }}>
                      {/* <p
                        className='m-0 mx-2 details'
                        style={{ textTransform: 'capitalize' }}
                      >
                        {t(`${reportType} Report Details`)}
                      </p> */}
                      <div
                        className='view-container'
                        key={index}
                        style={{ width: '100%' }}
                      >
                        <div className='row'>
                          {reportFields.details.map(field => (
                            <>
                                <div className='col-lg-3 mt-3' key={field.key}>
                                  <p className='label m-0'>{t(field.label)}</p>
                                  <p className='details m-0'>
                                    {record[field.key] || '-'}
                                  </p>
                                </div>
                              
                            </>
                          ))}
                        </div>

                        <div className='row'>
                          {reportFields.fields.map(field => (
                            <>
                                <div className='col-lg-3 mt-3' key={field.key}>
                                  <p className='label m-0'>{t(field.label)}</p>
                                  <p className='details m-0'>
                                    {record[field.key] || '-'}
                                  </p>
                                </div>
                              
                            </>
                          ))}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}

              {/* Footer */}
              <tr style={{ width: '100%' }}>
                <td
                  colSpan={2}
                  style={{ textAlign: 'left', padding: '10px 20px' }}
                >
                  <span style={{ fontSize: 12 }}>
                    All Rights Reserved{' '}
                    <span style={{ fontSize: 14, fontWeight: 600 }}>Jive</span>
                  </span>
                </td>
                <td style={{ textAlign: 'right', padding: '10px 20px' }}>
                  <span style={{ fontSize: 12 }}>
                    Page Number{' '}
                    <span
                      style={{ fontSize: 14, fontWeight: 600 }}
                      className='page-number'
                    >
                      01
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default PdfPreviewReport
