import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Theme } from '../../../Theme'
import { get } from '../../../services/booking'
import { useMutation, useQuery } from 'react-query'
import { t } from 'i18next'
import Status from '../../../components/common/Status'
import dayjs from 'dayjs'
import CButton from '../../../components/common/CButton'
import CustomAlert from '../../../components/common/CAlert'
import AlertModal from '../../../components/common/AlertModal'
import { getTheFirstTwoLetterOfString } from '../../../utils/functions'
import TimerCountDown from '../../../components/common/TimerCountDown'

const BookingDetails: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [bookingDetails, setBookingDetails] = useState<{
    [key: string]: any
  }>({})

  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const { mutate: getBookingDetails, isLoading: isFetchingCustomEventDetails } =
    useMutation(
      (id: any) => get('VendorFreelancerOrderService/GetById', { id: id }),
      {
        onSuccess: response => {
          if (response.succeeded) {
            setBookingDetails(response.data)
          } else {
            setAlert({
              message: response.message,
              type: 'error',
              visible: true
            })
          }
        },
        onError: (error: any) => {
          setAlert({
            message: error?.message || 'An error occurred. Please try again.',
            type: 'error',
            visible: true
          })
        }
      }
    )

  useEffect(() => {
    if (id !== 'new') {
      getBookingDetails(id)
    }
  }, [id, getBookingDetails])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [switchChecked, setSwitchChecked] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)

  const {
    data: statusData,
    isLoading: statusLoading,
    error: statusError,
    refetch
  } = useQuery(
    ['statusServiceOrder', id],
    () => {
      if (bookingDetails.createdByAdmin) {
        return (
          switchChecked &&
          get(`VendorFreelancerOrderService/${switchChecked}`, {
            orderReference: bookingDetails.orderReference
          })
        )
      } else {
        return (
          switchChecked &&
          get(`VendorFreelancerOrderService/${switchChecked}`, {
            OrderId: id // Default case
          })
        )
      }
    },
    {
      enabled: false,
      onSuccess: data => {
        setAlert({ message: data.message, type: 'success', visible: true })
      }
    }
  )

  const handleModalOk = async () => {
    await refetch()
    await getBookingDetails(id)
    setIsModalVisible(false)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  const callRequest = (val: string) => {
    if (bookingDetails.createdByAdmin) {
      setSwitchChecked(
        val === 'Approved' ? 'ApprovedWhatsupOrder' : 'RejectWhatsupOrder'
      )
    } else {
      setSwitchChecked(val)
    }
    setIsModalVisible(true)
    console.log('Value', val)
  }

  return (
    <>
      <div style={{ padding: '20px 0px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <img
              src={Theme.icons.icn_arrow_back}
              className='cursor-pointer'
              alt='icn_user_add'
              style={{ height: '20px' }}
              onClick={() => navigate(-1)}
            />
            <h4 className='list-heading m-0 mx-2'>{t('Details')}</h4>
          </div>
          {(bookingDetails?.status == 'Pending' ||
            bookingDetails?.status == 'AdminSubmitted') &&
            id != 'new' && (
              <div className='d-flex align-items-center w-25'>
                <CButton
                  style={{
                    marginRight: '12px',
                    backgroundColor: Theme.colors.success,
                    borderColor: Theme.colors.success,
                    width: '100%'
                  }}
                  onClick={() => callRequest('Approved')}
                >
                  Accept
                </CButton>
                <CButton
                  className='rejectBtn'
                  style={{ colo: 'red' }}
                  onClick={() => callRequest('Reject')}
                >
                  Reject
                </CButton>
              </div>
            )}
        </div>
      </div>

      {id != 'add' && (
        <>
          <div
            className='mt-3'
            style={{
              backgroundColor: Theme.colors.white,
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <h4 className='list-heading m-0 mx-2'>{t('Booking Details')}</h4>

            <div className='view-container mt-3'>
              <div className='d-flex align-items-center justify-content-between'>
                <div>
                  <p className='detail-label m-0'>Total Booking Amount</p>
                  <p className='detail-value m-0'>
                    {bookingDetails?.totalPrice
                      ? `SAR ${bookingDetails?.totalPrice}`
                      : '0'}
                  </p>
                </div>
                {/* counter is under progress */}
                {(bookingDetails?.status == 'Pending' ||
                  bookingDetails?.status == 'AdminSubmitted') &&
                  bookingDetails?.orderCancellationDate && (
                    <TimerCountDown
                      heading='Request Expire'
                      icon={Theme.icons.icn_timer_countdown}
                      backgroundColor={Theme.colors.white}
                      time={bookingDetails?.orderCancellationDate}
                    />
                  )}
              </div>
              <div className='view-container-content mt-3'>
                <div>
                  <p className='detail-label m-0'>Service Name </p>
                  <p className='detail-value m-0'>
                    {bookingDetails?.orderType == 'CustomEvent'
                      ? bookingDetails?.customEventName
                        ? bookingDetails?.customEventName
                        : bookingDetails?.serviceName
                      : bookingDetails?.serviceName}
                  </p>
                </div>
                <div>
                  <p className='detail-label m-0'>No of Attendees </p>
                  <p className='detail-value m-0'>
                    {bookingDetails?.noOfAttendees}
                  </p>
                </div>
                <div>
                  <p className='detail-label m-0'>Date</p>
                  <p className='detail-value m-0'>
                    {dayjs(bookingDetails?.serviceRequestDate)?.format(
                      'DD-MM-YYYY'
                    )}
                  </p>
                </div>
                <div>
                  <p className='detail-label m-0'>Time </p>
                  <p className='detail-value m-0'>
                    {bookingDetails?.serviceRequestStartTime} -{' '}
                    {bookingDetails?.serviceRequestEndTime}
                  </p>
                </div>
                <div>
                  <p className='detail-label m-0'>Service Type </p>
                  <p className='detail-value m-0'>
                    {bookingDetails?.eventType}
                  </p>
                </div>
                <div>
                  <p className='detail-label m-0 mb-1'>Status </p>
                  <Status status={bookingDetails?.status} />
                </div>
              </div>
              <div className='view-container-content mt-3'>
                <div>
                  <p className='detail-label m-0'>Location </p>
                  <p className='detail-value m-0'>
                    {bookingDetails?.userAddress}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className='mt-3'
            style={{
              backgroundColor: Theme.colors.white,
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <h4 className='list-heading m-0 mx-2'>{t('Service Details')}</h4>

            <div className='view-container mt-3'>
              <div className='d-flex align-items-center'>
                {bookingDetails?.serviceImages?.length > 0 && (
                  <img
                    src={
                      process.env.REACT_APP_S3_BUCKET_URL +
                      bookingDetails?.serviceImages[0]?.path
                    }
                    alt={bookingDetails?.serviceImages[0]?.originalFileName}
                    style={{
                      width: '200px',
                      height: '120px',
                      borderRadius: '20px'
                    }}
                  />
                )}
                <div className='mx-3'>
                  <p className='detail-value mb-1'>
                    {bookingDetails?.serviceName}
                  </p>
                  <p className='detail-label m-0 mb-1'>Features</p>
                  <p className='detail-value m-0' style={{ width: '70%' }}>
                    {bookingDetails?.features}
                  </p>
                </div>
              </div>
              <div
                className='view-container-content mt-3'
                style={{ width: '70%' }}
              >
                <div>
                  <p className='detail-label m-0'>Service Price </p>
                  <p className='detail-value m-0'>
                    {bookingDetails?.servicePrice}
                  </p>
                </div>
                <div>
                  <p className='detail-label m-0'>Category </p>
                  <p className='detail-value m-0'>
                    {bookingDetails?.categoryName}
                  </p>
                </div>

                <div>
                  <p className='detail-label m-0'>Service Duration </p>
                  <p className='detail-value m-0'>
                    {bookingDetails?.serviceStartTime} {' to '}
                    {bookingDetails?.serviceEndTime}
                  </p>
                </div>
              </div>
              <div className='view-container-content mt-3'>
                <div>
                  <p className='detail-label m-0'>Location </p>
                  <p className='detail-value m-0'>
                    {bookingDetails?.serviceLocation}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {bookingDetails?.packageId && (
            <>
              <div
                className='mt-3'
                style={{
                  backgroundColor: Theme.colors.white,
                  padding: '24px',
                  borderRadius: '16px'
                }}
              >
                <div className='view-container mt-3'>
                  <h4 className='list-heading m-0'>{t('Selected Package')}</h4>

                  <div
                    className='view-container-content mt-3'
                    style={{ width: '50%' }}
                  >
                    <div>
                      <p className='detail-label m-0'>Package Name </p>
                      <p className='detail-value m-0'>
                        {bookingDetails?.packageName}
                      </p>
                    </div>
                    <div>
                      <p className='detail-label m-0'>Package Price </p>
                      <p className='detail-value m-0'>
                        {bookingDetails?.packagePrice
                          ? `SAR ${bookingDetails?.packagePrice}`
                          : '0'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <AlertModal
        visible={isModalVisible}
        message={`Are you sure you want to ${
          switchChecked == 'Approved' ? 'Accept' : 'Reject'
        } the Booking?`}
        isCustomBtnText={true}
        customButtonLabels={{ txtCancel: 'No', txtOk: 'Yes' }}
        onDelete={handleModalOk}
        onCancel={handleModalCancel}
      />

      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default BookingDetails
