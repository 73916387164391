export const reportConfig = {
  order: {
    details: [
      { key: 'totalAmount', label: 'Total Amount', isStatus: false },
      { key: 'transactionId', label: 'Transaction ID', isStatus: false },
      { key: 'amountDiscounted', label: 'Amount Discounted', isStatus: false },
      { key: 'paymentStatus', label: 'Payment Status', isStatus: true }
    ],
    fields: [
      { key: 'orderId', label: 'Order ID', isStatus: false },
      { key: 'customerName', label: 'Customer Name', isStatus: false },
      { key: 'serviceName', label: 'Service Name', isStatus: false },
      { key: 'bookingDate', label: 'Booking Date', isStatus: false },
      { key: 'promoCode', label: 'Promo Code', isStatus: false },
      { key: 'bookingStatus', label: 'Booking Status', isStatus: true }
    ]
  },
  event: {
    details: [
      { key: 'eventBudget', label: 'Event Budget', isStatus: false },
      { key: 'eventType', label: 'Event Type', isStatus: false },
      { key: 'eventId', label: 'Event ID', isStatus: false },
      { key: 'eventStatus', label: 'Event Status', isStatus: true }
    ],
    fields: [
      { key: 'eventName', label: 'Event Name', isStatus: false },
      { key: 'organizerName', label: 'Customer Name', isStatus: false },
      {
        key: 'eventDateAndTime',
        label: 'Event Date and Time',
        isStatus: false
      },
      { key: 'duration', label: 'Duration', isStatus: false },
      { key: 'eventLocation', label: 'Event Location', isStatus: false }
    ]
  }
} as const

export type ReportConfig = typeof reportConfig
export type ReportType = keyof ReportConfig
