import React, { useEffect, useState } from 'react'
import { Form, Row, Col } from 'antd'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { changePassword } from '../../../services/auth'
import PasswordInput from '../../../components/common/PasswordInput'
import CInput from '../../../components/common/CInput'
import CButton from '../../../components/common/CButton'
import SuccessModal from '../../../components/common/SuccessModal'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import CustomAlert from '../../../components/common/CAlert'
import { get } from '../../../services/api/apiService'
interface ChangePasswordProps {
  switchToProfile: () => void;
}
const ChangePassword: React.FC<ChangePasswordProps> = ({ switchToProfile }) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const [isSuccess, setIsSuccess] = useState(false)
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [userDetails, setUserDetails] = useState<{ [key: string]: any }>({})

  const fetchUserDetails = () => {
    get('VendorFreelancerAccount/GetMyProfile', {}).then(response => {
      if (response.succeeded) {
        setUserDetails(response.data)
      } else {
        setAlert({ message: response.message, type: 'error', visible: true })
      }
    })
  }

  useEffect(() => {
    fetchUserDetails()
  }, [])

  useEffect(() => {
    form.setFieldValue('email', userDetails.email)
  }, [userDetails])

  const { mutate, isLoading } = useMutation(changePassword, {
    onSuccess: response => {
      if (response.succeeded) {
        setIsSuccess(true)
      }
      else{
        setAlert({ message: response.message, type: 'error', visible: true })
      }
    },
    onError: (error: unknown) => {
      if (error instanceof AxiosError) {
        let message = error.response?.data.errors[0]?.message
        console.log('An unknown error occurred:', message)

        setAlert({ message: message, type: 'error', visible: true })
      } else {
        console.log('An unknown error occurred:', error)
      }
    }
  })

  const handleSave = (values: {
    currentPassword: string
    newPassword: string
    confirmPassword: string
    email: string
  }) => {
    mutate(values) // Call the API
  }
  const handleOk = ()=>{
    setIsSuccess(false);
    switchToProfile();    
  }
  return (
    <>
      <Form
        form={form}
        layout='vertical'
        onFinish={handleSave}
        style={{
          backgroundColor: '#fff',
          padding: '24px',
          borderRadius: '16px'
        }}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name='currentPassword'
              label={t('Current Password')}
              rules={[
                {
                  required: true,
                  message: t('Please enter your current password')
                }
              ]}
            >
              <PasswordInput placeholder={t('Enter Current Password')} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name='newPassword'
              label={t('New Password')}
              rules={[
                {
                  required: true,
                  message: t('Please enter your new password')
                },
                { min: 6, message: t('Password must be at least 6 characters') }
              ]}
            >
              <PasswordInput placeholder={t('Enter New Password')} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name='confirmPassword'
              label={t('Confirm New Password')}
              dependencies={['newPassword']}
              rules={[
                {
                  required: true,
                  message: t('Please confirm your new password')
                },
                ({ getFieldValue }) => ({
                  validator (_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(t('Passwords do not match'))
                    )
                  }
                })
              ]}
            >
              <PasswordInput placeholder={t('Confirm New Password')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name='email'
              label={t('Email')}
              rules={[
                { required: true, message: t('Please enter your email') }
              ]}
            >
              <CInput
                disabled={true}
                placeholder={t('Enter Email')}
                value={userDetails.email}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8} style={{ marginTop: '15px' }}>
          <Col span={4}>
            <CButton
              loading={isLoading}
              style={{ width: '100%' }}
              htmlType='submit'
            >
              {t('Update Password')}
            </CButton>
          </Col>
        </Row>
      </Form>

      <SuccessModal
        visible={isSuccess}
        message={'Password changed successfully.'}
        onOk={handleOk}
      />

      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
    
  )
  
}

export default ChangePassword
