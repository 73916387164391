import React, { useState } from 'react'
import { Layout, Tag } from 'antd'
import { Theme } from '../../Theme'
import Header from '../../components/common/Header'
import MenuWrapper from '../../components/ui/MenuWrapper'
import { Outlet, useNavigate } from 'react-router-dom'

const { Sider, Content } = Layout

const getImage = (
  imgPath: string,
  selectedImgPath?: string,
  isSelected?: boolean
) => (
  <img
    src={isSelected && selectedImgPath ? selectedImgPath : imgPath}
    style={{ height: '20px' }}
    className='cursor-pointer'
  />
)
const PortalScreen: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState('servicesPackages')
  const [highlightStyle, setHighlightStyle] = useState({ top: 0, height: 0 })
  const [openKeys, setOpenKeys] = useState<string[]>([]) // Added to track open submenus
  const navigate = useNavigate()

  const items2 = [
    {
      key: 'dashboard',
      icon: getImage(Theme.icons.icn_dashboard, Theme.icons.icn_dashboard_white ,selectedKey == 'dashboard' ),
      label: 'Dashboard',
      path: '/dashboard' // Add path for navigation
    },
    {
      key: 'manageBooking',
      icon: getImage(
        Theme.icons.icn_manage_booking,
        Theme.icons.icn_manage_booking_white,
        selectedKey === 'manageBooking'
      ),
      label: 'Manage Booking',
      path: '/bookingList' // Add path for navigation
    },
    {
      key: 'servicesPackages',
      icon: getImage(
        Theme.icons.icn_services_packages,
        Theme.icons.icn_white_services_packages,
        selectedKey === 'servicesPackages'
      ),
      label: 'Services & Packages',
      path: '/servicesAndPackagesList'
    },
    {
      key: 'paymentLogs',
      icon: getImage(
        Theme.icons.icn_payment_logs,
        Theme.icons.icn_white_payment_logs,
        selectedKey === 'paymentLogs'
      ),
      label: 'Payments',
      path: '/paymentLogs'
    },
    {
      key: 'order_track',
      icon: getImage(
        Theme.icons.icn_order_track,
        Theme.icons.icn_order_track_white,
        selectedKey === 'order_track'
      ),
      label: 'Order Tracking',
      path: '/orderTracking'
    },
    // {
    //   key: 'chats',
    //   icon: getImage(Theme.icons.icn_reports, Theme.icons.icn_white_reports, selectedKey === 'reports'),
    //   label: 'Chats',
    //   path: '/',
    // },
    {
      key: 'promoCode',
      icon: getImage(
        Theme.icons.icn_promo,
        Theme.icons.icn_white_promo,
        selectedKey === 'promoCode'
      ),
      label: 'Promo Code',
      path: '/promoCodesList'
    },
    {
      key: 'reports',
      icon: getImage(
        Theme.icons.icn_reports,
        Theme.icons.icn_white_reports,
        selectedKey === 'reports'
      ),
      label: 'Reports',
      path: '/reports'
    },
    {
      key: 'notifications',
      icon: getImage(Theme.icons.icn_notification),
      label: 'Notifications',
      path: '/notifications'
    },
    {
      key: 'settings',
      icon: getImage(Theme.icons.icn_settings,Theme.icons.icn_setting_white,
        selectedKey === 'settings'),
      label: 'Settings',
      path: '/settings'
    }
  ]
  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key)
    localStorage.removeItem('active')
    // navigate(`/jive${e.path}`);

    // Find the menu item that was clicked
    const menuItem = items2
      .flatMap(item => [item])
      .find(item => item.key === e.key)

    // // Navigate to the path of the clicked menu item
    if (menuItem?.path) {
      navigate(`/jive${menuItem.path}`)
    }
  }

  const onLogOut = () => {
    localStorage.removeItem('authToken')
    navigate('/')
  }

  return (
    <Layout style={{ height: '100vh', overflowY: 'hidden' }}>
      <Sider
        width={260}
        style={{
          background: Theme.colors.white,
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: '#cdcdcd #f1f1f1'
        }}
      >
        <div
          className='mt-4'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img alt='logo' src={Theme.icons.icn_main_logo} />
        </div>
        <div
          className='d-flex flex-column justify-content-between'
          style={{ height: '90%' }}
        >
          <MenuWrapper
            items={items2}
            selectedKey={selectedKey}
            highlightStyle={highlightStyle}
            onClick={handleMenuClick}
            openKeys={openKeys}
            setOpenKeys={setOpenKeys}
          />
          <div className='d-flex align-items-center justify-content-center w-100 mb-3 cursor-pointer'>
            <Tag
              className='status-listing w-75'
              style={{ borderColor: '#F55666' }}
              onClick={onLogOut}
            >
              <img src={Theme.icons.icn_logout} style={{ height: '20px' }} />
              <p className='m-0 logout mx-2'>Logout</p>
            </Tag>
          </div>
        </div>
      </Sider>
      <Layout>
        <Header />
        <Content
          style={{
            padding: '24px',
            margin: 0,
            backgroundColor: Theme.colors.contentColor,
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: '#cdcdcd #f1f1f1'
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default PortalScreen
