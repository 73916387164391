import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Form } from "antd";
import CInput from "../../../components/common/CInput";
import AuthFrame from "../../../components/ui/AuthFrame";
import { Theme } from "../../../Theme";
import CButton from "../../../components/common/CButton";
import PasswordInput from "../../../components/common/PasswordInput";
import { useMutation, useQuery } from "react-query";
import { login } from "../../../services/auth";
import CustomAlert from "../../../components/common/CAlert";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getCountries } from "../../../services/manageUser";
import Phone from "../../../components/common/CPhone";
import CModal from "../../../components/common/CModal";
import CurrentLocationMap from "../../../components/ui/CurrentLocationMap";
import { CreateVendorFreelancerUser } from "../../../services/api/vendorFreelancer";

const switchLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
};

const SignUp: React.FC = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [role, setRole] = useState('');
    const [tab, setTab] = useState('role');
    const [address, setAddress] = useState('');
    const [showMap, setShowMap] = useState(false);
    const [phoneDetails, setPhoneDetails] = useState<{ phoneNumber: string; countryId: number; prefix: string }>({ phoneNumber: '', countryId: 0, prefix: '' });
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });

    const getLocation = () => {
        setShowMap(true);
    };

    const handleOk = () => {
        setShowMap(false);
    };

    const handleCancel = () => {
        setShowMap(false);
    };


    const handleLocationSelect = (location: string) => {
        setAddress(location);
    };


    

    
    const handlePhoneNumberChange = (details: { phoneNumber: string; countryId: number; prefix: string }) => {
        setPhoneDetails(details);
        if (details?.phoneNumber !== '') {
            form.setFieldsValue({
                phoneNumber: details?.prefix + details?.phoneNumber,
            });
        }else {
            form.setFieldsValue({
                phoneNumber: '',
            });
            
            // Revalidate the field to trigger the required rule
            // form.validateFields(['phoneNumber']);
        }
    };


    
    const { data: countryData, error: countryError, isLoading: isCountryLoading } = useQuery('countries', getCountries);

    const { mutate, isLoading, isError, error } = useMutation(CreateVendorFreelancerUser, {
        onSuccess: (response) => {
            if (response.succeeded) {
                // setAlert({ message: 'Sign Up Successful!', type: 'success', visible: true });
                // localStorage.setItem('authToken', response.data.token);

                navigate('/onSuccessSignUp',{state : {heading:"Successfully!",message:`You request has been sent successfully to admin for approval.`,btnText :"Continue",route : "/"}});
            } else {
                setAlert({ message: response.message || 'Login failed', type: 'error', visible: true });
            }
        },
        onError: (error: any) => {
            setAlert({ message: error ? error?.message : 'An error occurred during login. Please try again.', type: 'error', visible: true });
        },
    });



    const checkRole = (role: string) => {
        setRole(role);
    }

    const callOnboarding = () => {
        if(role === ''){
            setAlert({message: 'Please select the Role', type:'warning',  visible: true });
        }else{
            setTab('onBoarding');
        }
    }

    const handleSave = (values: any) => {
                const obj = {
            ...values,
            id: 0,
            CountryId: phoneDetails?.countryId,
            ShortPhoneNumber: phoneDetails?.phoneNumber,
            userType: role,
            // tabNumber: 1
        }
        console.log('values===>>>', obj);
        mutate(obj);
    }

    const handleNavigate = ()=>{
        if(role != ""){
            setRole("");
            setTab('role')
        }
        else{
            navigate(-1)
        }
    }

    return (
        <>
            <CustomAlert
                message={alert.message}
                type={alert.type as 'success' | 'info' | 'warning' | 'error'}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
            <div className="main-div">
                <div className="strech-man-div">
                    <img src={Theme.icons.icn_auth_side} alt="auth-side-logo" className="auth-side-logo" />

                    <p className="event-planner">Lorem ipsum dolor sit amet consectetur. Lectus quis sapien urna ultrices augue at amet eu nunc.</p>
                </div>
                <div
                    className="auth-form-div d-flex align-items-center justify-content-center"
                    style={{ margin: "20px", position: 'relative' }}
                >
                    <div className='d-flex align-items-left w-100' style={{ position: 'absolute', top: 20 }}>
                        <img
                            src={Theme.icons.icn_arrow_back}
                            className='cursor-pointer'
                            alt="icn_user_add"
                            style={{ height: '30px' }}
                            onClick={handleNavigate}
                        />
                    </div>
                    <div
                        className="auth-form-inner"
                        style={{
                            textAlign: "center",
                            margin: "20px"
                        }}
                    >
                        {tab === 'role' ? (
                            <>
                        <img src={Theme.icons.icn_main_logo} alt="logo" className=" my-2 auth-logo" />
                        <p className='login-heading my-3'>{t('Sign Up')}</p>
                        <p className="signup-des">Lorem ipsum dolor sit amet consectetur.</p>
                            <div className="d-flex justify-content-between mx-5 mt-4">
                                <div className="d-flex flex-column align-items-center check-mark-div cursor-pointer" style={{ width: '45%', borderColor: role === 'Vendor' ? Theme.colors.primary : '' }} onClick={() => checkRole('Vendor')}>
                                    <div className="d-flex justify-content-end w-100">
                                        <img src={Theme.icons.icn_tick} alt="icn-vendors-ignup" style={{ height: '24px', width: '24px', filter: role === 'Vendor' ? 'invert(64%) sepia(95%) saturate(678%) hue-rotate(93deg) brightness(101%) contrast(92%)' : 'none' }} />
                                    </div>
                                    <img src={Theme.icons.icn_vendor_signup} alt="icn-vendors-ignup" className="icn-check-signup my-2" />
                                    <p className="m-0 auth-role my-2">{t("Vendor")}</p>
                                    <p className="m-0 role-desc">Lorem ipsum dolor sit amet consectetur. Ligula imperdiet eros ipsum purus velit auctor scelerisque.</p>
                                </div>
                                <div className="d-flex flex-column align-items-center check-mark-div cursor-pointer" style={{ width: '45%', borderColor: role === 'Freelancer' ? Theme.colors.primary : '' }} onClick={() => checkRole('Freelancer')}>
                                    <div className="d-flex justify-content-end w-100">
                                        <img src={Theme.icons.icn_tick} alt="icn-vendors-ignup" style={{ height: '24px', width: '24px', filter: role === 'Freelancer' ? 'invert(64%) sepia(95%) saturate(678%) hue-rotate(93deg) brightness(101%) contrast(92%)' : 'none' }} />
                                    </div>
                                    <img src={Theme.icons.icn_freelancer_signup} alt="icn-vendors-ignup" className="icn-check-signup my-2" />
                                    <p className="m-0 auth-role my-2">{t("Freelancer")}</p>
                                    <p className="m-0 role-desc">Lorem ipsum dolor sit amet consectetur. Ligula imperdiet eros ipsum purus velit auctor scelerisque.</p>
                                </div>

                            </div>
                            <div className="w-100 d-flex justify-content-center mt-2">
                                <CButton loading={isLoading} disabled={false} className="mt-4 auth-btn w-50" onClick={() => callOnboarding()}>{t("Continue")}</CButton>
                            </div>
                            </>
                            ) : (
                                <>
                                <img src={role === 'vendor' ? Theme.icons.icn_vendor_signup : Theme.icons.icn_freelancer_signup} alt="logo" className="my-2" style={{height:'60px'}} />
                                <p className='login-heading my-3'>{ role === 'Vendor' ? t('Vendor Information') : t('Freelancer Information') }</p>
                                <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleSave}
                        initialValues={{ remember: true }}
                    >
        
        
        
                        <div className='row'>
                            <div className='col-lg-6'>
                                <Form.Item
                                    name="businessName"
                                    rules={[{ required: true, message: t("Please enter the business name") }]}
                                >
                                    <CInput label={t("Business Name")} placeholder={t("Enter Business Name")} />
                                </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                                <Form.Item
                                    name="contactPersonName"
                                    rules={[{ required: true, message: t("Please enter the contact person name") }]}
                                >
                                    <CInput label={t("Contact Person Name")} placeholder={t("Enter Contact Person Name")} />
                                </Form.Item>
                            </div>
        
                            <div className='col-lg-6'>
        
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter a valid email",
                                            validator: (_, value) => {
                                                if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                                                    return Promise.resolve();
                                                } else {
                                                    return Promise.reject("Please input your E-mail!");
                                                }
                                            },
                                        },
                                    ]}
                                >
                                    <CInput  label={t("email_address")} placeholder={t("email_placeholder")} />
                                </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                                <Form.Item
                                    name="websiteURL"
                                    rules={[{ required: true, message: 'Please enter the web url' }]}
                                >
                                    <CInput label={t("Website Link")} placeholder={t("Enter Website Link")} />
                                </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                                <Form.Item
                                    name="phoneNumber"
                                    rules={[{ required: true, message: 'Please enter the phone number' }]}
                                >
                                    {countryData && <Phone
                                        label='Phone Number'
                                        countries={countryData?.data}
                                        onPhoneNumberChange={handlePhoneNumberChange}
                                        // initialValue={state?.phoneNumber}
                                    />}
        
                                </Form.Item>
                            </div>
        
                            <div className='col-lg-6 d-flex align-items-center justify-content-between'>
                                <div style={{ width: '85%' }}>
                                    <Form.Item
                                        name="businessAddress"
                                        rules={[{ required: true, message: 'Please enter the business address' }]}
                                    >
                                        <CInput
                                            // className='w-100'
                                            label={t("Business Address")}
                                            placeholder={t("Enter Business Address")}
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </Form.Item>
                                </div>
        
        
                                <div className='inputfield d-flex  align-items-center p-2 cursor-pointer' style={{ marginTop: '16px' }} onClick={getLocation}>
                                    <img src={Theme.icons.icn_gps_location} alt="Get Location" />
                                </div>
                            </div>
        
                            <div className='col-lg-6'>
                            <Form.Item
                      name="password"
                      rules={[
                        {
                            required: true,
                            message: t('password_error'),
                        },
                        { min: 6, message: t('password_validation') },
                    ]}
                    >
                       <PasswordInput
                                            label={t('new_password')}
                                            placeholder={t('password_placeholder')}
                      />
                    </Form.Item>
                            </div>
                            <div className='col-lg-6'>
                                <Form.Item
                      name="confirmPassword"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: t('confirm_password_error'),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(t('confirm_password_validation'))
                            );
                          },
                        }),
                      ]}
                    >
                      <PasswordInput
                                            label={t('confirm_password')}
                                            placeholder={t('password_placeholder')}
                      />
                    </Form.Item>
                            </div>
                        </div>
        
        
        
                                    <div className="w-100 d-flex justify-content-center mt-2">
                                        <CButton loading={isLoading} disabled={false} className="mt-4 auth-btn w-50" htmlType="submit">{t("Continue")}</CButton>
                                    </div>
        
                                    {/* <p className="no-account mt-5">Already have an account! <strong style={{ color: Theme.colors.primary }} className="cursor-pointer" onClick={() => navigate('/')}>Login</strong></p> */}
                                </Form>
                                </>
                            )}

                            <p className="no-account mt-5">Already have an account! <strong style={{ color: Theme.colors.primary }} className="cursor-pointer forgot-pas-desc" onClick={() => navigate('/')}>Login</strong></p>

                        {/* <button onClick={() => switchLanguage('ar')}>Switch to Arabic</button>
                        
                        <button onClick={() => switchLanguage('en')}>Switch to english</button> */}
                        {/* {fields} */}
                    </div>
                </div>
            </div>
            <CModal
                title="Select Your Location"
                open={showMap}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
            >
                <CurrentLocationMap onLocationSelect={handleLocationSelect} />
                <div>
                    <strong>Selected Location:</strong> {address}
                </div>
            </CModal>
        </>
    );
};

export default SignUp;
