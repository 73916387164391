import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Theme } from '../../Theme'
import { t } from 'i18next'
import CustomAlert from '../../components/common/CAlert'
import { reportConfig, ReportType } from './ViewReportsConfig'
import Status from '../../components/common/Status'

const ViewReport: React.FC = () => {
  const navigate = useNavigate()
  const routeLocation = useLocation()
  const [alert, setAlert] = React.useState({
    message: '',
    type: 'success',
    visible: false
  })

  const record = routeLocation?.state?.record || {}
  const type = routeLocation?.state?.type || ''

  const reportType: ReportType = type ? type : 'order'
  const reportFields = reportConfig[reportType]

  return (
    <>
      <div style={{ padding: '20px 0px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <img
              src={Theme.icons.icn_arrow_back}
              className='cursor-pointer'
              alt='icn_user_add'
              style={{ height: '20px' }}
              onClick={() => navigate(-1)}
            />
            <p
              className='list-heading m-0 mx-2'
              style={{ textTransform: 'capitalize' }}
            >
              {t(`${reportType} Report`)}
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: Theme.colors.white,
          padding: '24px',
          borderRadius: '16px'
        }}
      >
        <div className='d-flex justify-content-between'>
          <p
            className='list-heading m-0 mx-2'
            style={{ textTransform: 'capitalize' }}
          >
            {t(`${reportType} Details`)}
          </p>

          <div
            className='download-btn d-flex gap-1'
            onClick={() =>
              navigate('/jive/pdfReportPreview', {
                state: {
                  data: { data: [record] },
                  startDate: record.bookingDate ? record.bookingDate : record.eventDateAndTime,
                  endDate: record.bookingDate ? record.bookingDate : record.eventDateAndTime,
                  type: reportType
                }
              })
            }
          >
            <img src={Theme.icons.icn_download} alt='Download' />
            <p className='m-0'>Preview & Download</p>
          </div>
        </div>
        <div className='view-container mt-3'>
          <div className='row'>
            {reportFields.details.map(field => (
              <>
                {field?.isStatus ? (
                  <div className='col-lg-3 mt-3' key={field.key}>
                    <p className='detail-label m-0'>{t(field.label)}</p>

                    <div style={{ maxWidth: 'max-content', marginTop: '5px' }}>
                      <Status status={record[field.key]} />
                    </div>
                  </div>
                ) : (
                  <div className='col-lg-3 mt-3' key={field.key}>
                    <p className='detail-label m-0'>{t(field.label)}</p>
                    <p className='detail-value m-0'>
                      {record[field.key] || '-'}
                    </p>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>

        <div className='view-container mt-3'>
          <div className='row'>
            {reportFields.fields.map(field => (
              <>
                {field?.isStatus ? (
                  <div className='col-lg-3 mt-3' key={field.key}>
                    <p className='detail-label m-0'>{t(field.label)}</p>

                    <div style={{ maxWidth: 'max-content', marginTop: '5px' }}>
                      <Status status={record[field.key]} />
                    </div>
                  </div>
                ) : (
                  <div className='col-lg-3 mt-3' key={field.key}>
                    <p className='detail-label m-0'>{t(field.label)}</p>
                    <p className='detail-value m-0'>
                      {record[field.key] || '-'}
                    </p>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>

      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default ViewReport
