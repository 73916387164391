import React, { useEffect, useState } from 'react'; 
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import Login from './pages/Auth/Login';
import ForgotPassword from './pages/Auth/Forgot';
import ResetPassword from './pages/Auth/Reset';
import PortalScreen from './pages/layout/PortalScreen';
import i18n from 'i18next';
import 'leaflet/dist/leaflet.css';
import Otp from './pages/Auth/Otp';
import ServicesAndPackagesList from './pages/ServicesAndPackages/ServicesAndPackagesList';
import CreateService from './pages/ServicesAndPackages/CreateService';
import ViewService from './pages/ServicesAndPackages/ViewService';
// import AddBooking from './pages/ManageBookings/CustomEvent/AddBooking';
import Notifications from './pages/ManageNotifications/Notifications';
import ViewProfile from './pages/Settings/Profile/ViewProfile';
import SignUp from './pages/Auth/SignUp/SignUp';
import OnBoarding from './pages/Auth/OnBoarding/OnBoarding';
import OnSuccessSignUp from './pages/Auth/SignUp/OnSuccessSignUp';
import CreatePackage from './pages/ServicesAndPackages/CreatePackage';
import ViewPackage from './pages/ServicesAndPackages/ViewPackage';
import PromoCodesList from './pages/PromoCodes/PromoCodesList';
import OrderTracking from './pages/OrderTracking/OrderTracking';
import ViewAndUpdateOrder from './pages/OrderTracking/ViewAndUpdateOrder';
// import BookingList from './pages/ManageBookings/Bookings/BookingList';
import ManageBooking from './pages/ManageBookings/Bookings/ManageBooking';
import BookingDetails from './pages/ManageBookings/Bookings/BookingDetails';
import ViewWhatsappBooking from './pages/Auth/ViewWhatsappBooking';
import ChangePassword from './pages/Settings/Profile/ChangePassword';
import ReportList from './pages/Reports/ReportList';
import PdfPreviewReport from './pages/Reports/PdfPreviewReport';
import ViewReport from './pages/Reports/ViewReport';
import Settings from './pages/Settings/Profile/Settings';
import Dashboard from './pages/Dashboard/Dashboard';

function App() {
const navigate = useNavigate();
useEffect(() => {
  const currentLanguage = i18n.language;
  document.documentElement.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';
}, [i18n.language]);


useEffect(() => {
  const handleLanguageChange = (lng: string) => {
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
  };

  i18n.on('languageChanged', handleLanguageChange);

  // Clean up the listener when the component unmounts
  return () => {
    i18n.off('languageChanged', handleLanguageChange);
  };
}, []);

  const switchToProfile = () => {
    navigate('/jive/settings');
  };

  return (
    <Routes>
    <Route index element={<Login />} />
    <Route path="signUp" element={<SignUp />} />
    <Route path="viewBooking" element={<ViewWhatsappBooking />} />
    <Route path="onSuccessSignUp" element={<OnSuccessSignUp />} />
    <Route path="onboarding" element={<OnBoarding />} />
    <Route path="forgot" element={<ForgotPassword />} />
    <Route path="otp" element={<Otp />} />
    <Route path="reset" element={<ResetPassword />} />
     <Route path="jive" element={<PortalScreen />} >
    {/* <Route path="manageInternalUser" element={<ManageUserList />} /> */}
    <Route path="servicesAndPackagesList" element={<ServicesAndPackagesList />} />
    <Route path="createService" element={<CreateService />} />
    <Route path="viewService/:id" element={<ViewService />} />
    <Route path="notifications" element={<Notifications />} />
    <Route path="settings" element={<Settings />} />
    <Route path="profile/:type" element={<ViewProfile />} />
    <Route path="createUpdatePackage" element={<CreatePackage />} />
    <Route path="viewPackage/:id" element={<ViewPackage />} />
    <Route path="promoCodesList" element={<PromoCodesList />} />
    <Route path="orderTracking" element={<OrderTracking />} />
    <Route path="orderTracking/ViewAndUpdateOrder/:id" element={<ViewAndUpdateOrder />} />
    <Route path="bookingList" element={<ManageBooking />} />
    <Route path="bookingList/view/:id" element={<BookingDetails />} />
    <Route path="profile/changePassword" element={<ChangePassword switchToProfile={switchToProfile}/>} />
    <Route path="reports" element={<ReportList />} />
    <Route path="pdfReportPreview" element={<PdfPreviewReport />} />
    <Route path="viewReport" element={<ViewReport />} />
    <Route path="dashboard" element={<Dashboard />} />


    {/*<Route path="manageCustomer" element={<ManageCustomerList />} />
    <Route path="manageFreelancer" element={<ManageFreelancerList />} />
    <Route path="vendorList" element={<VendorOnboardingList />} />
    <Route path="eventPolicyManagementList" element={<EventPolicyManagementList />} />
    <Route path="servicesAndPackagesList" element={<ServicesAndPackagesList />} />
    <Route path="reports" element={<ReportList />} />
    <Route path="PackagesList/:id" element={<PackagesList />} />
    <Route path="serviceBooking" element={<ServiceBookingList />} />
    <Route path="addNewUser" element={<AddNewUser />} />
    <Route path="eventType/:type/:id" element={<AddEventType />} />
    <Route path="categories/:type/:id" element={<AddCategories />} />
    <Route path="categories/:ParentId/subcategories/:type/:id" element={<AddSubCategories />} />
    <Route path="term-and-conditions/:type/:id" element={<AddTermAndCondition />} />
    <Route path="cancellation-policy/:type/:id" element={<AddCancellationPolicy />} />
    <Route path="bookingList" element={<BookingList />} />
    <Route path="customEvent/:type/:id" element={<BookingDetails />} />
    <Route path="customEvent/:requestId/booking/:type/:id" element={<AddBooking />} />
    <Route path="notifications" element={<Notifications />} />
    <Route path="notification/:type/:id" element={<ViewNotification />} />
    <Route path="vendorOnboarding" element={<VendorOnBoarding />} />
    <Route path="country/:type/:id" element={<AddUpdateCountry />} /> 
    <Route path="country/view/:id" element={<AddUpdateCountry />} />
    <Route path="country/:countryId/cities/:type/:id" element={<AddCity />} />
    <Route path="createService" element={<CreateService />} />
    <Route path="viewUser" element={<ViewUser />} />
    <Route path="viewFreelancer" element={<ViewFreelancer />} />
    <Route path="viewVendor" element={<ViewVendor />} />
    <Route path="viewServiceBooking/:id" element={<ViewServiceBooking />} />
    <Route path="viewService/:id" element={<ViewService />} />
    <Route path="pdfPreview" element={<PdfPreview />} />
    <Route path="viewReport" element={<ViewReport />} />
    <Route path="profile" element={<ViewProfile />} />
    <Route path="paymentLogs" element={<PaymentLogs />} />
    <Route path="profile/changePassword" element={<ChangePassword />} /> */}
     </Route>
     
    
    </Routes>
  );
}

export default App;
