import React, { useEffect, useState } from 'react'
import CustomTabs from '../../../components/common/CTabs'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import ViewProfile from './ViewProfile'
import ChangePassword from './ChangePassword'

const tabs = [
  { key: 'profile', label: 'Profile' },
  { key: 'password', label: 'Password' },
]

const Settings: React.FC = () => {
  const [activeKey, setActiveKey] = useState('profile')
  const { t } = useTranslation()
  const handleTabChange = (key: string) => {
    setActiveKey(key);
    localStorage.setItem('active',key);
  }

  useEffect(() => {
    const storedActiveTab = localStorage.getItem('active');
    if (storedActiveTab) {
      setActiveKey(storedActiveTab);
    }
  }, []);

  return (
    <>
      <p className='page-heading'>{t('Settings')}</p>
      <CustomTabs
        tabs={tabs}
        activeKey={activeKey}
        onTabChange={handleTabChange}
      />
      {activeKey === 'profile' && <ViewProfile />}
      {activeKey === 'password' && <ChangePassword switchToProfile={() => handleTabChange('profile')}/>}
    </>
  )
}

export default Settings
