import { ColumnsType } from 'antd/es/table'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Theme } from '../../../Theme'
import CustomAlert from '../../../components/common/CAlert'
import CInput from '../../../components/common/CInput'
import CTable from '../../../components/common/CTable'
import { useEffect, useState } from 'react'
import { get } from '../../../services/booking'
import { Tag } from 'antd'
import dayjs from 'dayjs'
import CustomTabs from '../../../components/common/CTabs'
import CButton from '../../../components/common/CButton'
import AlertModal from '../../../components/common/AlertModal'
import Status from '../../../components/common/Status'

const tabs = [
  { key: 'BookingRequest', label: 'New Requests' },
  { key: 'WhatsAppRequests', label: 'WhatsApp Requests' },
  { key: 'Approved', label: 'Approved' },
  { key: 'Booked', label: 'Booked' },
  { key: 'Completed', label: 'Completed' },
  { key: 'Cancelled', label: 'Rejected' },
  { key: 'Expired', label: 'Expired' }
]

const ManageBooking: React.FC = () => {
  const [alert, setAlert] = useState<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()
  const { t } = useTranslation()
  const status = searchParams.get('Status') || 'BookingRequest'
  const [activeKey, setActiveKey] = useState(status)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [switchChecked, setSwitchChecked] = useState('')
  const [orderId, setOrderId] = useState(null)

  const pageNumber = Number(searchParams.get('PageNumber')) || 1
  const pageSize = Number(searchParams.get('PageSize')) || 10
  const search = searchParams.get('Search') || ''
  const sortOrder = searchParams.get('SortOrder') || 'z'

  const handleTabChange = (key: string) => {
    setActiveKey(key)
    localStorage.setItem('active', key)
    setSearchParams(
      { Status: key, PageNumber: '1', PageSize: pageSize.toString() },
      { replace: true }
    )
  }

  useEffect(() => {
    const storedActiveTab = localStorage.getItem('active')
    if (storedActiveTab) {
      setActiveKey(storedActiveTab)
    }
  }, [])

  const handleSearchChange = _.debounce((e: any) => {
    setSearchParams(
      {
        Status: activeKey,
        PageNumber: '1',
        PageSize: pageSize.toString(),
        Search: e.target.value
      },
      { replace: true }
    )
  }, 1000)

  const handleSorting = () => {
    const newSortOrder = sortOrder === 'z' ? 'a' : 'z'
    setSearchParams(
      {
        Status: activeKey,
        PageNumber: pageNumber.toString(),
        PageSize: pageSize.toString(),
        Search: search,
        SortOrder: newSortOrder
      },
      { replace: true }
    )
  }

  const columns: ColumnsType = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('Event/Service Name'),
      render: (text, record) => <p className='m-0'>{record?.serviceName}</p>
    },
    {
      title: t('Attendees'),
      dataIndex: 'noOfAttendees',
      key: 'noOfAttendees'
    },
    {
      title: t('Booking Date'),
      key: 'date',
      render: (text, record) => (
        <p className='m-0'>
          {dayjs(record.serviceRequestDate)?.format('DD-MM-YYYY')}
        </p>
      )
    },
    {
      title: t('Time'),
      key: 'date',
      render: (text, record) => (
        <p className='m-0'>
          {record?.serviceRequestStartTime} - {record?.serviceRequestEndTime}
        </p>
      )
    },
    {
      title: t('Event Type'),
      dataIndex: 'eventTypeName',
      key: 'eventTypeName',
      render: (text, record) => (
        <Tag
          color={Theme.colors.primaryBase}
          className='status-tag'
          style={{ borderColor: Theme.colors.primaryBase }}
        >
          <span
            style={{
              fontSize: '20px',
              lineHeight: '1',
              color: Theme.colors.primaryBase
            }}
          >
            •
          </span>
          <span style={{ color: Theme.colors.primaryBase }}>{text}</span>
        </Tag>
      )
    },
    {
      title: t('Booking Status'),
      render: (text, record) => <Status status={record.status} />
    },
    {
      title: t('action'),
      render: (text, record) => (
        <div className='d-flex  align-items-center'>
          <img
            src={Theme.icons.icn_view_list}
            alt='icn_user_add'
            style={{ height: '20px', cursor: 'pointer' }}
            onClick={() => navigate(`/jive/bookingList/view/${record?.id}`)}
          />
          {record?.status === 'Pending' &&
            record?.status !== 'Expired' &&
            (activeKey === 'BookingRequest' ||
              activeKey === 'WhatsAppRequests') && (
              <>
                <CButton
                  style={{
                    marginLeft: '12px',
                    marginRight: '12px',
                    backgroundColor: Theme.colors.success,
                    borderColor: Theme.colors.success,
                    width: 'max-content',
                    height: '30px',
                    borderRadius: '20px'
                  }}
                  onClick={() => callRequest('Approved', record)}
                >
                  Accept
                </CButton>
                <CButton
                  className='rejectBtn'
                  style={{
                    colo: 'red',
                    height: '30px',
                    borderRadius: '20px',
                    width: 'max-content'
                  }}
                  onClick={() => callRequest('Reject', record)}
                >
                  Reject
                </CButton>
              </>
            )}
        </div>
      ),
      key: 'action'
    }
  ]

  const handleModalOk = async () => {
    await refetch();
    await refetchList();
    setIsModalVisible(false)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  const callRequest = (val: string, record: any) => {
    if (activeKey === 'WhatsAppRequests') {
      setSwitchChecked(val === 'Approved' ? 'ApprovedWhatsupOrder' : 'RejectWhatsupOrder');
      setOrderId(record.orderReference);
    } else {
      setSwitchChecked(val);
      setOrderId(record.id);
    }
    setIsModalVisible(true);
    console.log('Value', val);
  }

  // const {
  //   data: statusData,
  //   isLoading: statusLoading,
  //   error: statusError,
  //   refetch
  // } = useQuery(
  //   ['statusServiceOrder', orderId],
  //   () =>
  //     switchChecked &&
  //     get(`VendorFreelancerOrderService/${switchChecked}`, {
  //       OrderId: orderId
  //     }),
  //   {
  //     enabled: false,
  //     onSuccess: data => {
  //       setAlert({ message: data.message, type: 'success', visible: true })
  //     }
  //   }
  // )

  const {
    data: statusData,
    isLoading: statusLoading,
    error: statusError,
    refetch
  } = useQuery(
    ['statusServiceOrder', orderId],
    () => {
      if (activeKey === 'WhatsAppRequests') {
        return (
          switchChecked &&
          get(`VendorFreelancerOrderService/${switchChecked}`, {
            orderReference: orderId // Send 'orderReference' for WhatsApp requests
          })
        )
      } else {
        return (
          switchChecked &&
          get(`VendorFreelancerOrderService/${switchChecked}`, {
            OrderId: orderId // Default case
          })
        )
      }
    },
    {
      enabled: false,
      onSuccess: data => {
        setAlert({ message: data.message, type: 'success', visible: true })
      }
    }
  )

  const handleTableChange = (page: number, pageSize: number) => {
    setSearchParams(
      {
        Status: status,
        PageNumber: page.toString(),
        PageSize: pageSize.toString()
      },
      { replace: true }
    )
  }

  const { data, error, isLoading, refetch: refetchList, } = useQuery(
    [activeKey, pageNumber, pageSize, search, sortOrder],
    () =>
      get('VendorFreelancerOrderService/GetAll', {
        Status: activeKey,
        PageNumber: pageNumber,
        PageSize: pageSize,
        Search: search,
        SortColumn: 'Id',
        SortOrder: sortOrder
      }),
    {
      // keepPreviousData: true,
      onError: (err: any) => {
        setAlert({
          message: err?.message || t('An error occurred'),
          type: 'error',
          visible: true
        })
      }
    }
  )

  return (
    <>
      <>
        <p className='page-heading'>{t('Manage Bookings')}</p>
        <CustomTabs
          tabs={tabs}
          activeKey={activeKey}
          onTabChange={handleTabChange}
        />
      </>
      {/* <p className='page-heading'>{t('Booking List')}</p> */}
      <div className='d-flex justify-content-between align-items-center'>
        <p className='m-0 list-heading'>{t('Bookings List')}</p>
        <div className='d-flex justify-content-between'>
          <CInput
            prefix={
              <img
                alt=''
                src={Theme.icons.icn_search}
                style={{ height: '16px' }}
              />
            }
            className='searchInput'
            placeholder={t('Search')}
            onChange={handleSearchChange}
          />
          <div
            className='calendar-div'
            style={{ backgroundColor: '#D0C64F' }}
            onClick={() => handleSorting()}
          >
            <img
              src={Theme.icons.icn_sorting}
              alt='icn_user_add'
              style={{
                height: '20px',
                transform:
                  sortOrder === 'z' ? 'rotate(0deg)' : 'rotate(180deg)', // Rotates the icon by 180 degrees for ascending
                transition: 'transform 0.3s ease-in-out' // Smooth transition for the rotation
              }}
            />
          </div>
        </div>
      </div>
      <CTable
        footer='End of Table'
        columns={columns}
        dataSource={data?.data}
        pagination={{
          showSizeChanger: false,
          showQuickJumper: false,
          current: pageNumber,
          pageSize: pageSize,
          total: data?.totalRecords,
          onChange: handleTableChange,
          showTotal: (total: number, range: [number, number]) => (
            <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
          ),
          itemRender: (page, type, originalElement) => {
            if (type === 'prev') {
              return <p className='detail-label'>{`< Prev`}</p>
            }
            if (type === 'next') {
              return <p className='detail-label'>{`Next >`}</p>
            }
            return originalElement
          }
        }}
        loading={isLoading}
      />
      <AlertModal
        visible={isModalVisible}
        message={`Are you sure you want to ${
          switchChecked == 'Approved' ? 'Accept' : 'Reject'
        } the Booking?`}
        isCustomBtnText={true}
        customButtonLabels={{ txtCancel: 'No', txtOk: 'Yes' }}
        onDelete={handleModalOk}
        onCancel={handleModalCancel}
      />
      <CustomAlert
        message={alert.message}
        type={alert.type}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}
export default ManageBooking
